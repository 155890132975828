



























































































































































































































































































































































































































import {
  ref,
  defineComponent,
  useContext,
  onMounted,
  onBeforeUnmount,
  useRouter,
  useRoute,
  useFetch,
  computed,
} from "@nuxtjs/composition-api";
import { useUiHelpers } from "~/composables";
import { useCategoryStore } from "~/modules/catalog/category/stores/category";
import { SfOverlay } from "@storefront-ui/vue";
import type {
  ProductInterface,
  CategoryInterface,
} from "~/modules/GraphQL/types";
import useBrands from "~/composables/useBrands";
import useSubBrands from "~/composables/useSubBrands";
import { useBrandsStore } from "~/stores/brands";
import catListQuery from "~/modules/catalog/category/stores/graphql/categoryList.gql";
import { CategoryResult } from "~/composables";

export default defineComponent({
  name: "Megamenu",
  components: {
    SfOverlay,
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
  },

  setup() {
    const { app } = useContext();
    const { isMobileOrTablet } = app.$device;
    const { getCatLink } = useUiHelpers();
    let showMore = ref(false);
    let activeNav = ref(-1);
    let activeNavLvl2 = ref(-1);
    let showMoreThird = ref(false);
    let activeTirdNav = ref(0);
    let lvl2children = ref([]);
    let activeAccordion = ref(-1);
    let activeHover = ref(null);
    const isSearchOpen = ref(false);
    const router = useRouter();
    const route = useRoute();
    const fullPath = computed(() => route.value.fullPath);
    const storyBlokData = ref(null);
    const saleName = computed(() => storyBlokData.value?.sale_title);
    const saleLink = computed(() => storyBlokData.value?.sale_link);
    const catsTree = ref(null);
    const categories = useCategoryStore();

    const getCategories = async () => {
      const { data }: { data: { categories?: CategoryResult } } =
        await app.$vsf.$magento.api.customQuery({
          query: catListQuery,
        });
      catsTree.value = data?.categories.items[0];
    };

    const mobWidth = ref(1025);

    const updateScreenWidth = () => {
      mobWidth.value = window.innerWidth;
    };

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateScreenWidth);
    });

    const productSearchResults = ref<ProductInterface[] | null>(null);
    const categorySearchResults = ref<CategoryInterface[] | null>(null);
    const brandsResult = ref(null);
    const staticNav = computed({
      get: () => {
        return [
          {
            name: "Brands",
            url_path: "brands",
            url_suffix: "",
          },
          {
            name: "Inspiration",
            url_path: "inspiration",
            url_suffix: "",
          },
          {
            name: saleName.value,
            url_path: saleLink.value,
            url_suffix: ".html",
          },
          {
            name: "About us",
            url_path: "about-us",
            url_suffix: "",
          },
          {
            name: "Customer service",
            url_path: "customer-support",
            url_suffix: "",
          },
          {
            name: "Contact",
            url_path: "contact",
            url_suffix: "",
          },
        ];
      },
      set: (val) => {},
    });
    let searchVal = ref("");
    const onSearchValue = (searchValue) => {
      searchVal.value = searchValue;
    };
    const hoverMe = (index) => {
      if (index === -1) {
        showMore.value = false;
      } else {
        showMore.value = true;
      }
      activeNav.value = index;
    };
    const hoverMethird = (lvl2Children, index, hover) => {
      if (index === -1) {
        showMoreThird.value = false;
      } else {
        showMoreThird.value = true;
      }
      activeHover.value = hover;
      activeTirdNav.value = index;
      lvl2children.value = lvl2Children.children;
    };
    const closeMe = () => {
      showMore.value = false;
      activeNav.value = -1;
      showMoreThird.value = false;
      activeTirdNav.value = 0;
      lvl2children.value = [];
      lvl2children.value = [];
      activeHover.value = "";
    };
    const checkLength = (twocolumns) => {
      if (twocolumns > 9) {
        return true;
      }
    };
    const toggleAcc = (index, j) => {
      if (index === activeNav.value) {
        activeNav.value = -1;
      } else {
        activeNav.value = index;
        if (j) {
          activeNavLvl2.value = j;
        }
      }
    };

    const toggleAccordion = (lvl1) => {
      if (lvl1 === "lvl0") {
        showMore.value = !showMore.value;
      }
      const lvl = lvl1.toString();
      if (activeAccordion.value === lvl) {
        let arr = lvl.split("-").slice(0, -1);
        if (arr.join("-").length > 0) {
          activeAccordion.value = arr.join("-");
        } else {
          activeAccordion.value = -1;
        }
      } else {
        activeAccordion.value = lvl;
      }
    };
    const closeNavAndContinue = (route) => {
      activeNav.value = -1;
      showMore.value = false;
      activeAccordion.value = -1;
      router.push(app.localePath(route));
    };

    const { getData } = useBrands();
    const { getData: getSubBrandsData } = useSubBrands();
    const brands = ref(null);
    const brandsStore = useBrandsStore();

    const generateBrandsUrl = (brand) => {
      return `/brand/${brand.url}`;
    };

    useFetch(async () => {
      await categories.load();
      await app.$storyblok("sale-page-settings").then((res) => {
        storyBlokData.value = res.story.content;
      });
      await getCategories();
    });

    onMounted(async () => {
      mobWidth.value = window.innerWidth;
      window.addEventListener("resize", updateScreenWidth);
      await getData().then((res) => {
        brandsStore.updateBrands(res);
        brands.value = res;
      });
      await getSubBrandsData().then((res) => {
        brandsStore.updateSubBrands(res);
      });
      
    });
    const getHeight = (n) => {
      if (process.client) {
        return window.innerHeight * n + "px";
      }
    };

    let totalRes = ref(null);
    const getTotalResults = (setTotalResults) => {
      totalRes.value = setTotalResults;
    };
    const setNoScrollOnBody = (showMoreVal) => {
      showMore.value = showMoreVal;
      if (showMore.value === true) {
        document.body.classList.add("no-scroll-hover-menu");
      } else {
        document.body.classList.remove("no-scroll-hover-menu");
      }
    };
    const checkChildrenLength = (items) => {
      return items.filter((item) => item.children.length > 0);
    };
    return {
      totalRes,
      getTotalResults,
      getCatLink,
      catsTree,
      hoverMe,
      showMore,
      activeNav,
      showMoreThird,
      activeTirdNav,
      hoverMethird,
      activeHover,
      lvl2children,
      closeMe,
      checkLength,
      staticNav,
      isMobileOrTablet,
      toggleAcc,
      toggleAccordion,
      activeNavLvl2,
      activeAccordion,
      isSearchOpen,
      closeNavAndContinue,
      productSearchResults,
      categorySearchResults,
      brands,
      generateBrandsUrl,
      brandsResult,
      getHeight,
      fullPath,
      onSearchValue,
      searchVal,
      mobWidth,
      storyBlokData,
      saleName,
      setNoScrollOnBody,
      checkChildrenLength,
    };
  },
});
