































import {
  defineComponent,
  useRouter,
  useContext,
  computed,
  onUpdated,
} from "@nuxtjs/composition-api";
import { SfButton, SfTopBar } from "@storefront-ui/vue";
import { useTopBar } from "./useTopBar";
import { useUser } from "~/modules/customer/composables/useUser";
import { useUiState } from "~/composables";
import userGetters from "~/modules/customer/getters/userGetters";

export default defineComponent({
  components: {
    StoreSwitcher: () => import("../StoreSwitcher.vue"),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { toggleLoginModal } = useUiState();

    const username = computed(() => userGetters.getFirstName(user.value));

    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const isLogged = computed(() => isAuthenticated.value);
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: "customer-order-history" }));
      } else {
        // toggleLoginModal();
        router.push(app.localePath("/login"));
      }
    };
    onUpdated(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });
    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
      handleAccountClick,
      accountIcon,
      username,
      isLogged,
    };
  },
});
